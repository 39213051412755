<template>
  <div class="bodycont">

    <comHeader />
    <div class="infodiv">
      <navTitle :navData='navData' />

      <div class="topdiv">
        <div class="bassMess counter-box py-2 my-2">

          <div class="kyzb_aa">
            <div class="kyzb_aal">
              <div class="kyzb_aaa kyzb_aaa1">
                <div class="kyzb_aaac">
                  <p>需求编号</p>
                  <p>{{dataInfo.ureqId}}</p>
                </div>
              </div>

              <div class="kyzb_aab">
                <div class="kyzb_aaba">

                  <h1>{{dataInfo.ureqName}}</h1>
                </div>
                <div class="kyzb_aabb">
                  <div class="kyzb_aabba">
                    <p>
                    投资方式：{{dataInfo.ureqInvestmentWay}}
                    </p>
                    <p>
                     需求关键字：{{dataInfo.ureqKeyWord}}
                    </p>
                    <p>
                     阶段成果：{{dataInfo.ureqStageResults}}
                    </p>

                  </div>

                </div>
                <span class="blank20"></span>

                <br>

              </div>
            </div>
            <div class="kyphone">
              <p>发布时间：{{dataInfo.ureqAuditTime}}</p>
              <p>联系人：{{dataInfo.ureqPerson}}</p>
              <!-- <p>联系方式：{{dataInfo.ureqTel}}</p> -->
            </div>
          </div>


        </div>
      </div>



      <div class="kyzb_ac">
        <h2>需求概况</h2>
        <div class="kyzb_aca">
          <div class="levelTit">
            <p>需求详述</p>
            <div class="colorBox"></div>
          </div>
          <div>{{dataInfo.ureqSurvey}}</div>

          <div class="levelTit">
            <p>预期效果</p>
            <div class="colorBox"></div>
          </div>
          <div>{{dataInfo.ureqExpectedEffect}}</div>

          <div class="levelTit">
            <p>需求详情</p>
            <div class="colorBox"></div>
          </div>
          <div v-html="dataInfo.ureqDetail"></div>
        </div>
      </div>

    </div>
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      navTitle,

    },
    data() {
      return {
        isIndex: 1,
        isshow: false,
        textarea: '',
        navData: [{
            name: '查看需求',
            url: 'prelease',
          },
          {
            name: '详情页',
            url: 'pdetails',
          },
        ],
        ureqId: '',
        dataInfo: {},
      }
    },
    mounted() {
      this.ureqId = this.$route.query.id
      this.getInfo()
    },
    methods: {
      //获取列表
      getInfo() {
        let data = {
          ureqId: this.ureqId
        }
        postRequest(api.requireDetails, data).then(res => {
          this.dataInfo = res.data
        })
      },
      //切换选项
      changeindex(item) {
        this.isIndex = item
      },


      changeleave() {
        this.isshow = !this.isshow
      }
    }
  }
</script>

<style lang="less" scoped>
  .infodiv {
    width: 1180px;
    margin: 0 auto;
    min-height: 77vh;
  }




  .leftContent {
    width: 860px;
    margin-bottom: 20px;


    .bassMess {
      position: relative;
      padding: 24px;
      border-top: 6px solid #0e86d1;
      box-shadow: 0 0 20px #ddd;
      overflow: hidden;
      border-radius: 0 0 5px 5px;
      margin-bottom: 20px;
    }

    .tit_small {
      color: #999;
    }

    .company {
      color: #ff1b1b;
    }

    .levelMess {
      width: 100%;
      overflow: hidden;
      margin-bottom: 20px;
    }

    .showview {
      margin: 10px 30px;
    }

    .showview div[class*=col-sm] {
      color: #323232;
      font-size: 15px;
      padding: 8px 0;
    }

    .showview .title {
      color: #999;
    }

    .showview span {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .tit {
    margin-bottom: 10px;
  }

  .topdiv {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;

    .topimg {
      margin-right: 20px;
    }




  }




  .kyzb_ac {
    margin: 20px 0px 20px;
    border: 1px #ccc solid;

    h2 {
      background: #3d9ee4;
      height: 50px;
      line-height: 48px;
      color: #fff;
      font-size: 20px;
      font-weight: normal;
      padding: 0 20px;
    }

    .kyzb_aca {
      padding: 20px;
      font-size: 14px;
      line-height: 2em;
    }

    .kyzb_aca h3 {
      // background: url(../images/tj/kyzb_7.png) no-repeat 0 5px;
      font-size: 16px;
      padding-left: 15px;
      border-left: 5px solid #00428E;
    }

    .blank20 {
      clear: both;
      display: block;
      height: 20px;
      overflow: hidden;
    }


  }


  .kyzb_aa {
    padding: 30px;
  }

  .kyzb_aal {
    width: 600px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-start;
  }

  .kyzb_aa #showtext {
    float: right;
    width: 480px;
    padding-top: 30px;
    // background: url(../images/tj/kyzb_1.png) no-repeat right top;
  }

  .kyzb_aaa1 {
    background: url(../../assets/images/marker.png) no-repeat 0 bottom;

  }

  .kyzb_aaa {
    float: left;
    width: 110px;
    height: 110px;
  }

  .kyzb_aaac {
    padding-top: 15px;
    height: 93px;
  }

  .kyzb_aaac {
    height: 79px;
    padding-top: 29px;
    border: 1px #ccc solid;
    text-align: center;
    font-size: 14px;

    p {
      line-height: 25px;
      height: 25px;
      overflow: hidden;
    }
  }

  .kyzb_aab {
    width: 470px;
    margin-left: 40px;
  }

  .kyzb_aaba {
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    margin-bottom: 20px;

    h1 {
      font-size: 22px;
      font-weight: normal;
    }
  }

  .kyzb_aabba {
    overflow: hidden;
    line-height: 28px;
    font-size: 14px;
  }

  .blank20 {
    clear: both;
    display: block;
    height: 20px;
    overflow: hidden;
  }

  .fl {
    float: left;
  }

  .kyzb_aa {
    display: flex;
    width: 100%;
  }

  .kyphone {

    p {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .levelTit {
    position: relative;
    height: 60px;

    p {
      width: 100%;
      height: 30px;
      line-height: 30px;
      margin: 20px 0;
      font-size: 18px;
      color: #323232;
      border-left: 6px solid #1277fd;
      padding-left: 26px;
      z-index: 2;
      vertical-align: top;
      position: absolute;
      left: 0;
      bottom: 0;



      .colorBox {
        height: 10px;
        width: 68px;
        background: #c0d4fb;
        z-index: 1;
        position: absolute;
        left: 29px;
        bottom: 20px;
      }
    }
    }
</style>
